import React from 'react'
import { graphql } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import { Link } from 'gatsby'
import { IndexQueryQuery, PostByPathQuery } from '../../../types/graphql-types'
import Meta from 'components/meta/meta'
import Layout from 'components/layout/layout'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import Video from 'components/video'
import BackgroundImage from 'gatsby-background-image'
import OGImage from 'images/happy-father-with-little-son-at-home.jpg'
import Logo from 'images/logo.png'
import { Helmet } from 'react-helmet'
import BtnOutlined from 'components/button/outlined-button'
import TelLinkSimple from 'components/tel-link-simple'

interface Props {
  data: IndexQueryQuery
  location: Location
}

const FamilyDentistryPage: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  const hero = data.hero?.childImageSharp?.fluid
  const hero_background = data.hero_background?.childImageSharp?.fluid
  const pd_cta_background = data.pd_cta_background?.childImageSharp?.fluid
  const service_family_1 = data.service_family_1?.childImageSharp?.fluid
  const service_family_2 = data.service_family_2?.childImageSharp?.fluid

  return (
    <Layout location={location}>
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <title>Family Dentistry - The Dentists At Gateway Crossing</title>
      <meta name="description" content="We offer whole family dentistry services to help you and your kids achieve optimal oral health and perfect smiles. Browse the page below to learn more. It's" />
      <meta name="robots" content="index, follow, max-snippet:-1, max-video-preview:-1, max-image-preview:large" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="article" />
      <meta property="og:title" content="Family Dentistry - The Dentists At Gateway Crossing" />
      <meta property="og:description" content="We offer whole family dentistry services to help you and your kids achieve optimal oral health and perfect smiles. Browse the page below to learn more. It's" />
      <meta property="og:url" content="https://thedentistsatgc.com/services/family-dentistry/" />
      <meta property="og:site_name" content="The Dentists At Gateway Crossing" />
      <meta property="article:publisher" content="https://www.facebook.com/thedentistsatgc/" />
      <meta property="og:updated_time" content="2021-02-05T05:10:25+00:00" />
      <meta property="og:image" content={OGImage} />
      <meta property="og:image:secure_url" content={OGImage} />
      <meta property="og:image:width" content="1823" />
      <meta property="og:image:height" content="2000" />
      <meta property="og:image:alt" content="happy-father-with-little-son-at-home" />
      <meta property="og:image:type" content="image/jpeg" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Family Dentistry - The Dentists At Gateway Crossing" />
      <meta name="twitter:description" content="We offer whole family dentistry services to help you and your kids achieve optimal oral health and perfect smiles. Browse the page below to learn more. It's" />
      <meta name="twitter:image" content={OGImage} />
      <meta name="google-site-verification" content="khYQGQ-T8YWkXa0QyKr6e2kNlFB3l0ZiFRA89VzVbaM" />
      <meta name="google-site-verification" content="Oll6PjpMRzNx1KbgKilxS6xPddvSbF2lDOa2rd7VkxA" />
      <meta name="msapplication-TileImage" content={Logo} />
      <script async src='https://tag.simpli.fi/sifitag/195cd770-bf42-013a-5143-0cc47a8ffaac'></script>
    </Helmet>
      <BackgroundImage
        Tag="section"
        className="hero-section-bg"
        fluid={hero_background}
        backgroundColor={`#e9f4f8`}
      >
        <div className ="col-lg-12" id ="color-overlay">
        </div>
        <div id="hero-section">
          <Container>
            <div className="col-lg-12 text-center">
              <p className="hero-text">
              FAMILY DENTISTRY MCCORDSVILLE
              </p>
              <h1 className="text-light">
              McCordsville Family Dentistry
              </h1>
            </div>
            <div className="col-lg-12 text-center">
              <p className="hero-text text-light">We offer whole family dentistry services to help you and your kids achieve optimal oral health and perfect smiles. Browse the page below to learn more. It’s easy to schedule your appointment with us. </p>
              <p className="hero-text text-light">Just click “schedule now” as soon as you’re ready.</p>
            </div>
            <div className="col-lg-12 text-center">
              <a href="#pd-section-3"><button className="btn-rounded"> Learn More </button></a>
              <a href="https://flexbook.me/tdagc/website" target="_blank"><button className="btn-rounded"> Schedule Now </button></a>
            </div>
          </Container>
          </div>
      </BackgroundImage>
      <section id ="pd-section-1" className="page-section text-center">
          <Container>
            <div className="col-lg-12">
              <p className ="section-1-text">FAMILY DENTAL SERVICES</p>
              <h2 className ="section-2-header">Your Family's Dental Care Is Important!</h2>
              <p>Spending time with your family is priceless. We understand that and that is why we offer dental services for the whole family. This way you can save time by bringing everyone to the same place. You can rest easy knowing that your dentist has the experience to take good care of all of your loved ones and the ability to see them all in one location. Our state of the art dental office along with Dr. Vogt’s training is sure to meet your entire family’s dental needs.</p>
            </div>
          </Container>
          <Container>
            <div className="col-lg-4">
              <div className="columns">
                <i className="fa fa-bolt" aria-hidden="true"></i>
                <h3 className ="section-1-header">Happy Kids</h3>
                <p>Dr. Vogt is great with children. He has a unique way of helping families actually enjoy the dentist. His personality and caring nature makes all the difference.</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="columns">
                <i className="fa fa-diamond" aria-hidden="true"></i>
                <h3 className ="section-1-header">Money In Your Pocket</h3>
                <p>Families can be expensive, but they don't have to be. Bring your whole family in to save big on time and energy.</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="columns">
                <i className="fa fa-bookmark-o" aria-hidden="true"></i>
                <h3 className ="section-1-header">Feel Comfortable</h3>
                <p>We make sure that everyone is comfortable and at ease when in the waiting room and during procedures.</p>
              </div>
            </div>
          </Container>
          <Container>
            <div className="col-lg-4">
              <div className="columns">
                <i className="fa fa-bolt" aria-hidden="true"></i>
                <h3 className ="section-1-header">Rest Easy</h3>
                <p>Your entire family is in good hands. Dr. Vogt takes ten times the recommended continuing education. You can be assured that your family dental issues will be properly identified and solved.  </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="columns">
                <i className="fa fa-diamond" aria-hidden="true"></i>
                <h3 className ="section-1-header">Less Stress</h3>
                <p>Smiles make everyone happier. Your entire household will have better energy and mood when they don't have to worry about their dental health.</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="columns">
                <i className="fa fa-bookmark-o" aria-hidden="true"></i>
                <h3 className ="section-1-header">Get More Done</h3>
                <p>When you spend less time at the dentist you have more time for life's other activities. Bringing the whole family to one place is a huge time saver!</p>
              </div>
            </div>
          </Container>
          <Container>
            <div className="col-lg-6">
              <Img fluid={service_family_1} alt="Happy Father Holding His Son" />
            </div>
            <div className="col-lg-6">
              <Img fluid={service_family_2} alt="Mother and Daughter Wearing Stripe Shirt Playing and Smiling" />
            </div>
          </Container>
        </section>
        <BackgroundImage
          Tag="section"
          className="pdsection2-bg"
          fluid={pd_cta_background}
          backgroundColor={`#e9f4f8`}
          alt="The Dentists at Gateway Crossing"
        >
          <div className ="col-lg-12" id ="color-overlay">
          </div>
          <section id="pdsection-2" className="page-section">
            <Container>
              <div className="col-lg-6">
                <p className="hero-text">
                ACTUALLY ENJOY THE DENTIST!
                </p>
                <h3 className="text-light">
                Don't miss your chance to see Dr. Vogt!
                </h3>
                <p className="hero-text text-light">
                The word is out! We provide the best experience in family dental care. Spots are limited.  Call TODAY!
                </p>
                <BtnOutlined url="tel:3176439434" class="btn-rounded" label="(317) 643-9434" />
              </div>
            </Container>
            </section>
          </BackgroundImage>
          <section id ="pd-section-3" className="page-section">
          <Container>
            <div className="col-lg-12 text-center">
              <p className ="section-1-text">FAMILY DENTAL DETAILS</p>
              <h2 className ="section-3-header">What Makes a Family Dentist Great?</h2>
             <p>As a parent you want a family dentist that you can trust to take great care of your family’s teeth.  You want the ability to have great rapport with your dentist and an environment that is family friendly. Dr. Vogt is known to make every visit a walk in the park and that is something all of our patients experience. As a dental professional, Dr. Vogt has worked with adult dental issues and pediatric dental care in very young children. He is gentle, smart, and kids love him. We understand how important your family is to you and that is why we provide a 5 star family dentistry experience. We see lots of happy families, take a look at our reviews to see for yourself.</p>
             <p>After fulling cleaning the inside of the tooth a rubber-like material will be placed where the pulp once was and a cap will placed on top. This will look and feel just like your natural tooth. You can bite with normal pressure  and your chewing will not be affected.</p>
            </div>
          </Container>
          <Container>
            <div className="col-lg-12 text-left">
              <div className="columns">
                <h3 className ="section-3-header">Is A Family Dentist right for you?</h3>
                <p>If you have more than one person under your care or living in your household a family dentist like Dr. Vogt can make all the difference in the world. Very few people like going to the dentist in general, but at our office we strive to make sure that you don’t feel that way. </p>
              </div>
            </div>
            <div className="col-lg-12 text-left">
              <div className="columns">
                <h3 className ="section-3-header">Our Family Dentistry Services List</h3>
                <p>Our family dentals services covers all types of dentistry. The list below outlines some of the most common,</p>
                <ul className="check-list">
                  <li className="fa fa-check"><Link to="/services/preventive-dentistry">Preventive Dentistry- Exams,cleanings,fluoride,sealants</Link></li>
                  <li className="fa fa-check"><Link to= "/services/restorative-dentistry">Restorative Dentistry - Fillings, crowns, dental implants, bridges, and dentures</Link></li>
                  <li className="fa fa-check"><Link to= "/services/cosmetic-dentistry"> Cosmetic Dentistry - Teeth whitening, bonding, contouring, veneers</Link></li>
                  <li className="fa fa-check"><Link to= "/services"> And many more... See all our services in one place by clicking here</Link></li>
                </ul>
              </div>
            </div>
            <div className="col-lg-12 text-left">
              <div className="columns">
                <h3 className ="section-3-header">Are My Family's Dental Procedures Covered By My Insurance?</h3>
                <p>Every patient’s situation is different. We would love to discuss payment options with you. Please call our office at <TelLinkSimple />.</p>
              </div>
            </div>
          </Container>
        </section>
    </Layout>
  )
}

export default FamilyDentistryPage

export const query = graphql`
  query FamilyDentistryPageQuery {
    hero: file(name: { eq: "hero-image" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    pd_cta_background: file(name: { eq: "placeholder" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    hero_background: file(name: { eq: "about-us-hero" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    service_family_1: file(name: { eq: "family_1" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    service_family_2: file(name: { eq: "family_2" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
        twitter
        
      }
    }
  }
`
